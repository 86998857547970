@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  /* * { */
  /* @apply focus:outline-[0]; */
  /* } */

  body {
    @apply !font-nunito text-base leading-6 text-black/60;
  }

  .wrapper {
    @apply max-w-[1440px] ml-auto mr-auto;
  }

  .circle {
    @apply rotate-[-110deg] origin-center;
  }

  table th {
    @apply text-left font-bold;
  }

  table thead tr {
    @apply border-b border-solid border-black/10;
  }

  table tbody tr {
    @apply border-b border-solid border-black/10;
  }

  table td,
  table th {
    @apply py-5 text-base text-black px-2;
  }

  table td a {
    @apply text-purple underline text-base;
  }
}

/* .brain-sidebar li a:hover:before{ */
/* content: ""; */
/* position: absolute; */
/* top: -20px; */
/* right: 0; */
/* width: 20px; */
/* height: 20px; */
/* background: transparent; */
/* border-bottom-right-radius: 20px; */
/* box-shadow: 7.5px 7.5px 0 7.5px #fff; */
/* } */
/* .brain-sidebar li a:hover::after { */
/* content: ""; */
/* position: absolute; */
/* bottom: -20px; */
/* right: 0; */
/* width: 20px; */
/* height: 20px; */
/* background: transparent; */
/* border-top-right-radius: 20px; */
/* box-shadow: 7.5px -7.5px 0 7.5px #fff; */
/* } */
/* .brain-sidebar li a:hover:before { */
/* border-bottom-right-radius: 20px; */
/* } */
/* .brain-sidebar li a:hover::after { */
/* border-top-right-radius: 20px; */
/* } */
.cs-select div[class*="-indicatorContainer"] {
  color: #828282 !important;
  padding: 0.5px 0px;
}

/* .ck-editor {
  min-height: 150px;
  overflow-y: unset !important;
} */

/* .ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #f6f6f6;
} */
/* 
.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow-y: auto !important;
  height: 25vh !important;
} */

.quill {
  overflow: unset !important;
}

body {
  margin: 0px;
}

.svg-logo-wrap {
  width: 400px;
  overflow: unset;
}

.ck-editor__main img {
  width: 500px;
}

.apiLoader-svg-outline-wrap {
  width: 100px;
  overflow: unset;
}

.apiLoader-svg-outline {
  stroke: #9b51e0;
  strokewidth: 1px;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: outline 3s linear forwards infinite;
}

@keyframes outline {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    stroke-dashoffset: 0;
    transform: translateY(-15px);
    fill: #9b51e0;
    animation: all 6s ease-in-out;
  }

  60% {
    transform: translateY(-10px);
  }
}

.svg-logo {
  fill: #9b51e0;
  animation: bounce 2s infinite;
}

.apexcharts-canvas {
  @apply mx-auto;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.svg-text {
  stroke: #9b51e0;
  strokewidth: 1px;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: draw 3s linear forwards infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
    fill: #9b51e0;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 200;
  }

  to {
    stroke-dashoffset: 1000;
  }
}

.svg-subtext {
  fill: #000;
}

.svg-subtext {
  animation: fade-in 1s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .svg-logo-wrap {
    width: 300px;
  }
}

/* .ql-editor img {
  width: auto;
  height: auto;
  object-fit: content;
} */

@media only screen and (max-width: 767px) {
  /* .ql-editor img {
    width: 300px;
    height: 300px;:
    object-fit: content;
  } */
}

.apexcharts-svg {
  overflow: visible;
}

.font-stroke-1 {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
}

.stable-scroll {
  scrollbar-gutter: stable;
}

.slick-slide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.section-style .slick-dots li.slick-active button:before {
  @apply !text-purple;
}

.section-style .slick-dots {
  @apply absolute top-1/2 -translate-y-1/2 bottom-[unset];
}

.section-style .slick-dots li {
  @apply !block !ml-auto;
}

.section-style .slick-dots li button:before {
  font-size: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #ddd;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}

/* .progress-tracker-wrap div[type="line"] {
  width: 900px;
} */
.progress-tracker-wrap div[type="line"] .apexcharts-canvas {
  @apply !w-[50vw] md:w-[600px] lg:w-[900px];
}

figure.table {
  @apply relative overflow-x-auto mt-5;
}

figure.table table {
  @apply !w-fit !text-sm text-left !text-black !bg-white;
}
figure.table table thead tr {
  @apply bg-white border-b;
}
figure.table table tbody {
  @apply text-xs !text-black !bg-white;
}

figure.table table tbody td,
figure.table table thead th {
  @apply px-6 py-5 border !w-fit;
}

figure.table table tbody:first-child tr {
  @apply text-xs text-black bg-white;
}

figure.table table tbody tr,
figure.table table thead tr {
  @apply bg-white border-b;
}

.ckEditor ol,
.ckEditor ul {
  @apply pl-5;
}

.ckEditor ol li {
  @apply !list-decimal;
}

.ckEditor ul li {
  @apply !list-disc;
}

.ckEditor li {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

p {
  word-wrap: break-word;
}

.rich-editor ol,
.rich-editor ul {
  @apply pl-5;
}

.rich-editor ol li {
  @apply !list-decimal;
}

.rich-editor ul li {
  @apply !list-disc;
}

.rich-editor.table {
  @apply relative overflow-x-auto mt-5;
}

.rich-editor table {
  @apply !w-fit !text-sm text-left text-black bg-white;
}

.rich-editor table tbody {
  @apply text-xs text-black bg-white;
}

.rich-editor table tbody td {
  @apply !px-6 !py-5 border !w-fit;
}

.rich-editor table tbody:first-child tr {
  @apply text-xs text-black bg-white;
}

.rich-editor table tbody tr {
  @apply bg-white border-b;
}

.rich-editor img {
  width: 50rem;
}

.rich-editor * {
  font-family: Nunito, sans-serif !important;
}

.ck .ck-content h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content h3 {
  display: block;
  font-size: 1.25em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: normal;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.ck .ck-content p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h3 {
  display: block;
  font-size: 1.25em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: normal;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.rich-editor li {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.lesson-image-size img {
  width: 35rem;
}
